<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
  </div>
</template>

<script>
import ModalComponent from "@/components/modals/index.vue";

export default {
  components: { ModalComponent }
};
</script>

<style lang="stylus">
@import "assets/styles/index.styl"
#app {
  background-image url("./assets/images/bg.jpg")
  background-repeat no-repeat
  background-size cover
  background-position center bottom
}
</style>